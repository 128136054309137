<template>
  <div class="page">
    <v-header/>
    <div class="user-level">
      <div v-for="item in userLevels" class="user-level-item">
        <div class="user-level-item-left">{{item.name}}</div>
        <div class="user-level-item-right">
          <div class="user-level-item-point">累积 <span class="user-level-item-point user-level-item-point-value">{{item.minPoint}}</span> 奖励严值自动升级</div>
          <div class="user-level-item-award">完成任务 <span class="user-level-item-award user-level-item-award-value">+{{item.awardPercent}}%奖励严值</span></div>
        </div>
      </div>
    </div>
  </div>
</template>


-------------------- script --------------------

<script>
  import {userLevelConfigV2Api} from "../../api/v2/userLevelConfigV2Api";

  export default {
    data() {
      return {
        userLevels: []
      }
    },

    async created() {
      this.userLevels = await userLevelConfigV2Api.findAll();
    }
  }
</script>


-------------------- style --------------------

<style scoped lang="less">
  .page{
    height: 100vh;
    width: 100vw;
    background: #f4f4f4;
  }

  .user-level{
    padding-top: 40px;
  }

  .user-level-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 20px;
    padding: 24px 16px;
    background: #fff;
    border-radius: 6px;
  }

  .user-level-item-left{
    font-size: 26px;
    color: #151d36;
    font-weight: bolder;
  }

  .user-level-item-right{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 12px;
  }

  .user-level-item-point{
    color: #666;
  }

  .user-level-item-point-value{
    color: #151d36;
    font-weight: bolder;
  }

  .user-level-item-award{
    margin-top: 12px;
  }

  .user-level-item-award-value{
    color: #ee4a50;
    font-weight: bolder;
  }

</style>
